/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTypes from '../form/ActionTypes';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { ActionPropertiesProps, EditorProps } from './FormBuilderTypes';

const editors: Record<keyof typeof ActionTypes, FC<EditorProps>> = Object.assign(
  {},
  ...Object.entries(ActionTypes).map(([key, info]) => ({
    [key]: info.resourceEditor,
  })),
);

const ResourceActionEditor: FC<ActionPropertiesProps> = (props) => {
  const { action, patchAction, form, invalidStates, patchForm, referencedForms, builderBaseUrl } = props;

  const { t } = useTranslation('form-builder');

  if (!action) {
    return null;
  }

  return (
    <div data-cy="action-properties">
      <div className="flex justify-between">
        <div>
          <Heading size={HeadingSize.H4} data-cy="action-title">
            {t('action-properties.common.properties', { type: t(`action-types.${action.type}`) })}
          </Heading>
        </div>
      </div>
      {editors[action.type] ? (
        createElement(editors[action.type], {
          key: action.id,
          action,
          patchAction,
          patchData: (data) => patchAction({ data: Object.assign(action?.data || {}, data) }),
          form,
          invalidStates,
          patchForm,
          referencedForms,
          builderBaseUrl,
          patchTranslations: (translations) => patchAction({ translations }),
        })
      ) : (
        <div className="italic" data-cy="no-properties-warning">
          {t('action-properties.common.properties-empty', { type: t(`action-types.${action.type}`) })}
        </div>
      )}
    </div>
  );
};

export default ResourceActionEditor;
